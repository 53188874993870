import { createGlobalStyle } from "styled-components";
import { normalize } from "polished";

import "./app.css";

const GlobalStyles = createGlobalStyle`
  /* ${normalize} */
  *, *:before, *:after {
    box-sizing: border-box;
  }

  body {
    padding: 0 !important;
    background-color: white;
  }

  h1, h2, h3, h4, h5, h6 {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin: 0;
    grid-column: 1 / -1;
  }

  fieldset {
    padding: 0;
    border: none
  }
`;

export default GlobalStyles;
