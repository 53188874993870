import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

import App from "./App";
import ThemeProvider from "./theme/provider";

if (process.env.NODE_ENV === "production")
  Sentry.init({
    dsn:
      "https://1750a2a2228a442280c036fd2fb9cdb2@o380479.ingest.sentry.io/5644437",
  });

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
