import styled from "styled-components";
import { smooth } from "../../utilities/styling";

import { spacing, border, mono } from "../../theme/theme";

export const Card = styled.div`
  color: inherit;
  width: 100%;
  margin: 0 auto;

  ${smooth("padding", spacing[16], spacing[40], "320px", "450px")}
  border-radius: ${border.radius[3]};

  background-color: ${mono.fff};
  box-shadow: ${border.shadow.light};
`;
