import React, { lazy } from "react";
import { useRoutes } from "react-router-dom";

import Loader from "./components/Loader";
import { ErrorBoundary } from "./utilities/errorBoundary";
import Layout from "./components/Layout";
import ErrorFallback from "./components/Error";

const Redirect = lazy(() => import("./views/redirect"));
const Register = lazy(() => import("./views/register"));

const App = () => {
  const element = useRoutes([
    { path: "/", element: <Redirect /> },
    {
      path: "/:id",
      element: <Register />,
    },
  ]);

  return (
    <ErrorBoundary
      fallback={
        <Layout>
          <ErrorFallback
            title="There was an error loading the application"
            text="This has been reported automatically."
          />
        </Layout>
      }
    >
      <Layout>
        <React.Suspense fallback={<Loader />}>{element}</React.Suspense>
      </Layout>
    </ErrorBoundary>
  );
};

export default App;
