import styled from "styled-components";
import BackgroundImage from "../../assets/images/background.png";

import { border, color, mono, typography } from "../../theme/theme";
import { TBtnVariant } from ".";

const getBackgroundColor = (variant: TBtnVariant) => {
  if (variant === "secondary") return color.primary;
  return mono[111];
};

export const Content = styled.span``;

interface IButton {
  $variant: TBtnVariant;
  $block: boolean;
}

export const Button = styled.button<IButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 1.75rem 12px 1.75rem;

  border: 1px solid transparent;
  border-radius: ${border.radius[24]};

  background-color: ${(p) => getBackgroundColor(p.$variant)};
  background-image: url(${BackgroundImage});
  background-repeat: repeat;
  background-position: center;
  background-size: 70%;

  color: ${mono.fff};
  line-height: 22px;
  text-transform: uppercase;
  font-size: ${typography.size[12]};
  font-weight: ${typography.weight[600]};

  min-width: 150px;
  ${(p) => (p.$block ? `min-width: 100%` : "")};

  &:disabled {
    cursor: not-allowed;
    filter: contrast(0.5);
  }
  &:hover,
  &:focus {
  }
  &:active {
  }
`;

const getLinkColor = (variant: TBtnVariant) => {
  if (variant === "secondary") return color.primary;
  return mono[808080];
};

export const LinkButton = styled.button<IButton>`
  display: flex;
  justify-content: start;
  padding: 14px 0 12px 0;
  border: none;
  background-color: transparent;
  outline: none;

  color: ${(p) => getLinkColor(p.$variant)};
  letter-spacing: -0.23px;
  line-height: 22px;
  font-size: ${typography.size[14]};
  font-weight: ${typography.weight[600]};

  min-width: 150px;
  ${(p) => (p.$block ? `min-width: 100%;   justify-content: center;` : "")};

  &:disabled {
    cursor: not-allowed;
    filter: contrast(0.5);
  }
  &:focus {
  }
  &:hover {
  }
  &:active {
  }
`;

export const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 1.75rem 12px 1.75rem;

  border: 1px solid transparent;
  border-radius: ${border.radius[24]};

  background-color: ${color.primary};
  background-image: url(${BackgroundImage});
  background-repeat: repeat;
  background-position: center;
  background-size: 70%;

  color: ${mono.fff};
  line-height: 22px;
  text-transform: uppercase;
  font-size: ${typography.size[12]};
  font-weight: ${typography.weight[600]};
  text-align: center;

  min-width: 150px;

  &:disabled {
    cursor: not-allowed;
    filter: contrast(0.5);
  }
  &:hover,
  &:focus {
    filter: brightness(1.2);
  }
  &:active {
    filter: brightness(0.9);
  }
`;
