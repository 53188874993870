import { fluidRange } from "polished";

/**
 * Smooth transition of prop between values
 * @param prop
 * @param fromSize
 * @param toSize
 * @param fromMedia 320px
 * @param toMedia 1200px
 */
export const smooth = (
  prop: string,
  fromSize: string | number,
  toSize: string | number,
  fromMedia = "320px",
  toMedia = "1200px"
) => {
  return fluidRange({ prop, fromSize, toSize }, fromMedia, toMedia);
};
